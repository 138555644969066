/* eslint-disable no-restricted-globals */

import { AirgapAPI } from '@transcend-io/airgap.js-types';

// we partner with Transcend to manage cookie consent and regulate cookies/data flows according to the user's consent choices
// the purpose of the functions here are to ensure Transcend's 'airgap' script is loaded ahead of third party services (if required)
// see https://docs.transcend.io/docs/consent/reference/api

// getCookieConsent(true) will load airgap.js

const airgapEvent = new Event('cookie-consent-ready');

declare global {
  interface Window {
    airgap: AirgapAPI | undefined;
    transcend?: {
      setActiveLocale: (locale: string) => void;
    };
  }
}

let airgapAPI: Promise<AirgapAPI> | undefined;

export const getCookieConsent = (
  consentRequired: boolean,
  locale = 'en-US',
) => {
  // if consent management is not required (ie LaunchDarkly flag returns false)
  // resolve the promise and allow cookieConsentReady to resolve and resume data flows
  if (!consentRequired) {
    document.body.setAttribute('data-cookie-consent-ready', 'true');
    window.dispatchEvent(airgapEvent);
    return Promise.resolve();
  }

  if (airgapAPI) {
    return airgapAPI;
  }

  airgapAPI = new Promise((resolve, reject) => {
    // Stub airgap.js ready queue
    if (!self?.airgap?.ready) {
      self.airgap = {
        readyQueue: [],
        ready(callback) {
          this.readyQueue?.push(callback);
        },
        ...self?.airgap,
      } as AirgapAPI;
    }

    // Wait for airgap.js to be ready
    self.airgap.ready(airgap => {
      window.dispatchEvent(airgapEvent);
      document.body.setAttribute('data-cookie-consent-ready', 'true');
      resolve(airgap);
    });

    const script = document.createElement('script');

    // Reject promise if airgap.js fails to load
    script.addEventListener('error', evt => {
      reject(evt);
    });

    // Load airgap.js script asynchronously
    script.setAttribute('data-tamper-resist', 'off');
    script.setAttribute('data-prompt', 'auto');
    script.async = true;
    script.defer = true;
    script.src = `https://transcend-cdn.com/cm/664fcdf5-95e5-454c-9c7d-c0b79de39a53/airgap.js`;
    // Ensure locale is set when the script loads
    script.addEventListener('load', () => {
      // Retry setting the locale until it's successfully applied
      const interval = setInterval(() => {
        if (window.transcend?.setActiveLocale) {
          window.transcend.setActiveLocale(locale);
          clearInterval(interval);
        }
      }, 100);
    });
    document.documentElement?.appendChild?.(script);
  });
  return airgapAPI;
};

export const cookieConsentReady = (): Promise<boolean> => {
  return new Promise(resolve => {
    if (document.body.dataset.cookieConsentReady === 'true') {
      resolve(true);
    }

    const handler = () => {
      resolve(true);
      window.removeEventListener('cookie-consent-ready', handler);
    };

    window.addEventListener('cookie-consent-ready', handler);
  });
};
